
import {defineComponent,reactive,toRefs,onMounted } from "vue";
import formatDate from './../../util/formatDate';
import ImeiSeach from "@/components/search/ImeiSeach.vue";
import ServerChoose from '@/components/ServerChoose.vue';
import {getImeiList} from "@/api/index";
import { useStore } from 'vuex';
interface searchDataAny {
    customerName:'',
    dateValue: '',
    statusVal:string,
}
export default defineComponent({
    name:'RobotImei',
    components:{
        ImeiSeach,
        ServerChoose
    },
    setup(){
        const store = useStore();
        const deviceData = reactive({
            loading:true,
            iMeiData:[],
            total:2,
            pageSize:10,
            pageSizes:[10],
            currentPage:1,
            startDate:'',
            endDate:'',
            status:'',
            keyWord:'',
            statusOption:[
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: '0',
                    label: '未绑定',
                },
                {
                    value: '3',
                    label: '已绑定(宠物)',
                },
            ],
        });
        const data = toRefs(deviceData);
        const formatDateF = formatDate.formatDateTime;
        const searchData = (e:searchDataAny)=>{
            let startDate,endDate;
            if(e){
                startDate = formatDate.handleTime(e.dateValue[0],"yyyy-MM-dd HH:mm:ss");
                endDate = formatDate.handleTime(e.dateValue[1],"yyyy-MM-dd HH:mm:ss");
            }
            deviceData.startDate = startDate;
            deviceData.endDate = endDate;
            deviceData.status = e.statusVal;
            deviceData.keyWord = e.customerName;
            deviceData.currentPage = 1;
            deviceData.loading = true;
            getImeiListFun();
        }
        const editerCustomer = ((e:any)=>{
            console.log('编辑');
        });
        const deleteCustomer = ((e:any)=>{
            console.log('删除');
        });

        const handleSizeChange = (val:any) => {
          //设置当前页显示几条数据
            deviceData.pageSize = val;
        }
        const handleCurrentChange = (val:any) => {
            //当前页码
            deviceData.currentPage = val;
            deviceData.loading = true;
            getImeiListFun();
        }
        const getImeiListFun = async()=>{
            let start:any = (deviceData.currentPage-1)*10;
            let params ={
                ip:store.state.chooseIp,
                start:start,
                length:deviceData.pageSize,
                draw:deviceData.currentPage,
                startDate:deviceData.startDate,
                endDate:deviceData.endDate,
                status:deviceData.status,
                keyWord:deviceData.keyWord,
            }
            await getImeiList(params).then((res:any)=>{
                deviceData.loading = false;
                if(res.data){
                  deviceData.iMeiData = res.data;
                  deviceData.total = res.recordsTotal;
                }
            });
        }
        onMounted(async()=>{
            deviceData.loading = true;
            getImeiListFun();
        });
        return {
            ...data,
            formatDateF,
            searchData,
            editerCustomer,
            deleteCustomer,
            handleSizeChange,
            handleCurrentChange
        }
    }
})
